<template>
  <li
    class="list-group-item px-0 py-4"
  >
    <div class="row">
      <div class="col-12">
        <FormInput
          v-model="result.name"
          label="Course Name"
        />
        <FormSelect
          v-model="result.course_type_id"
          label="Course Type"
          :options="course_types.data"
        />
        <FormInput
          v-model="result.week_hours"
          type-number
          label="Week Hours"
        />
        <FormSelect
          v-model="result.timetable"
          label="Class Time"
          :options="[
            { id: 'morning', name:'Mañana' },
            { id: 'afternoon', name:'Tarde' },
            { id: 'evening', name:'Noche' },
            { id: 'morning-afternoon', name:'Mañana y tarde' },
          ]"
        />
        <FormCheckbox
          v-model="result.is_work_study"
          label="Is Work & Study"
          class="mt-3"
        />
        <FormInput
          v-if="result.is_work_study"
          v-model="result.prices[0].week_price"
          label="Course Price"
          class="mb-4"
        />
      </div>

      <div
        v-if="!result.is_work_study"
        class="col-12"
      >
        <table class="table coursePrices">
          <tr>
            <td>
              <small>Course Price Ranges</small>
            </td>
            <td
              colspan="2"
              class="text-right"
            >
              <button
                class="btn btn-outline-info btn-sm"
                @click="addPriceRange"
              >
                New Price Range
                <i class="fas fa-plus" />
              </button>
            </td>
          </tr>
          <tr
            v-for="(item, index) in result.prices"
            :key="item.key"
          >
            <td>
              <FormInput
                v-model="item.from_weeks"
                label="From # Weeks"
                :disabled="item.from_weeks === 1"
              />
            </td>
            <td>
              <FormInput
                v-model="item.week_price"
                label="Week Price"
              />
            </td>
            <td>
              <button
                v-if="item.from_weeks > 1"
                class="btn btn-outline-danger btn-sm"
                @click="deletePriceRange(index)"
              >
                <i class="fas fa-times" />
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <button
      class="btn btn-outline-danger btn-sm btn-block"
      @click="deleteCourse()"
    >
      <i class="fas fa-times mr-2" /> Delete Course
    </button>
  </li>
</template>

<script>
import { FormInput, FormSelect, FormCheckbox } from '@/components/inputs';
import { mapGetters } from 'vuex';

export default {
  components: {
    FormInput,
    FormSelect,
    FormCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      result: this.value,
    };
  },
  computed: {
    ...mapGetters(['course_types']),
  },
  watch: {
    result: {
      deep: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    deleteCourse() {
      this.$emit('onDelete');
    },
    addPriceRange() {
      this.result.prices.push({
        key: Math.random() * Date.now(),
        week_price: 0,
        from_weeks: null,
        course_id: this.result.id,
      });
    },
    deletePriceRange(i) {
      this.result.prices.splice(i, 1);
    },
  },
};
</script>

<style>
table.coursePrices {
  margin-top: 10px;
}
table.coursePrices td {
  padding: 5px 0 !important;
  border: unset;
}
table.coursePrices td .form-group {
  margin: 0 5px !important;
}
</style>
