<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      Academies Form
    </template>
    <template slot="body">
      <!-- Academy Details -->
      <div class="card">
        <div class="card-body row">
          <div class="col-12">
            <h4>Details</h4>
          </div>
          <div class="col-12">
            <img
              :src="logo || default_logo"
              style="vertical-align: bottom; max-width: 100px"
            >
            <img-uploader
              class="ml-2 d-inline"
              @error="displayError"
              @input="onUploadImage"
            >
              <button class="btn btn-sm btn-outline-success">
                Upload new Logo
              </button>
            </img-uploader>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="result.name"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Budget Expiration Time <small>(days)</small></label>
              <input
                v-model="result.budget_expire_time"
                type="number"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-6">
            <FormSelect
              v-model="result.city_id"
              label="Location"
              :options="cities.data"
            />
          </div>
          <div class="col-6">
            <FormSelect
              v-model="result.supplier_id"
              label="Accommodation Supplier"
              :options="suppliers_list"
            />
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Book Fee</label>
              <input
                v-model="result.book_fee"
                type="number"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Enrolment Fee</label>
              <input
                v-model="result.enrolment_fee"
                type="number"
                class="form-control"
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Academy Courses -->
      <div class="card mt-3">
        <div class="card-body">
          <h4>Courses</h4>
          <ul
            v-if="result.courses.length"
            class="list-group list-group-flush"
          >
            <courseListItem
              v-for="(course, i) in result.courses"
              :key="course.key"
              :value="course"
              @input="updateCourse($event,i)"
              @onDelete="deleteCourse(i)"
            />
          </ul>

          <p
            v-else
            class="text-center py-3 bg-light"
          >
            <small>
              This Academy has no Courses, please add a new course.
            </small>
          </p>
          <div
            class="btn btn-sm btn-outline-primary btn-block"
            @click="addCourse"
          >
            <i class="fas fa-plus mr-2" />
            Add New Course
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div
        class="btn btn-sm btn-outline-success"
        @click="save"
      >
        <i class="fas fa-save mr-2" />
        Save
      </div>
    </template>
  </vue-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import defaultLogo from '@/assets/default-logo.png';
import {
  FormSelect,
} from '@/components/inputs';
import courseListItem from './courseListItem.vue';

export default {
  components: {
    FormSelect,
    courseListItem,
  },
  props: {
    academy: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    default_logo: defaultLogo,
    logo: null,
    result: {
      name: null,
      logo: null,
      website: null,
      location: null,
      budget_expire_time: null,
      enrolment_fee: null,
      book_fee: null,
      courses: [
        {
          key: Math.random() * Date.now(),
          name: null,
          week_hours: null,
          timetable: 'morning',
          prices: [
            {
              key: Math.random() * Date.now(),
              week_price: 0,
              from_weeks: 1,
            },
          ],
        },
      ],
    },
    apiURL: process.env.VUE_APP_API_URL,
  }),
  computed: {
    ...mapGetters(['cities', 'suppliers_list']),
  },
  mounted() {
    if (this.academy) {
      this.result = { ...this.academy };
      if (this.academy.logo) {
        this.logo = (this.academy.logo.substr(0, 4) !== 'http'
          ? `${this.apiURL}${this.academy.logo}`
          : this.academy.logo);
      }
    }
    this.getCities({ per_page: 1000 });
    this.getCourseTypes({ per_page: 1000 });
    this.getSuppliersList();
  },
  methods: {
    ...mapActions([
      'getCourseTypes',
      'createAcademy',
      'updateAcademy',
      'getCities',
      'getSuppliersList',
    ]),
    close() {
      this.$refs.modal.close();
    },
    addCourse() {
      this.result.courses.push({
        key: Math.random() * Date.now(),
        name: null,
        week_hours: null,
        timetable: 'morning',
        prices: [
          {
            key: Math.random() * Date.now(),
            week_price: 0,
            from_weeks: 1,
            course_id: null,
          },
        ],
      });
    },
    deleteCourse(index) {
      this.fireConfirm().then(() => {
        this.result.courses.splice(index, 1);
      });
    },
    updateCourse(value, index) {
      this.result.courses[index] = value;
    },
    displayError(e) {
      this.$noty.error(e);
    },
    onUploadImage(e) {
      this.logo = e.imageURL;
      this.result.logo = e.imageFile;
    },
    validate() {
      let valid = true;
      if (!this.result.name) {
        this.$noty.error('The Academy Name is required');
        valid = false;
      }
      if (!this.result.logo) {
        this.$noty.error('The Academy Logo is required');
        valid = false;
      }
      if (!this.result.city_id) {
        this.$noty.error('The Academy Location is required');
        valid = false;
      }
      if (!this.result.budget_expire_time) {
        this.$noty.error('The Academy Bugdet Expiration Time is required');
        valid = false;
      }
      if (!this.result.enrolment_fee && this.result.enrolment_fee !== 0) {
        this.$noty.error('The Academy Enrolment Fee is required');
        valid = false;
      }
      if (!this.result.book_fee && this.result.book_fee !== 0) {
        this.$noty.error('The Academy Book Fee is required');
        valid = false;
      }
      if (!this.result.courses.length) {
        this.$noty.error('The Academy must have at least 1 course');
        valid = false;
      } else {
        let index = 1;
        // eslint-disable-next-line no-restricted-syntax
        for (const c of this.result.courses) {
          if (!c.name) {
            this.$noty.error(`Course #${index} Name is required`);
            valid = false;
          }
          if (!c.week_hours) {
            this.$noty.error(`Course #${index} Week Hours is required`);
            valid = false;
          }
          if (c.timetable === null || c.timetable === '') {
            this.$noty.error(`Course #${index} Class Time is required`);
            valid = false;
          }
          if (!c.prices.length) {
            this.$noty.error(`Course #${index} - At least 1 Price Range is required`);
            valid = false;
          } else {
            let validPrices = true;
            for (const a of c.prices) {
              if (a.from_weeks < 1 || !a.week_price) validPrices = false;
            }
            if (!validPrices) {
              this.$noty.error(`Course #${index} - Complete all Price Range attributes`);
              valid = false;
            }
          }
          index += 1;
        }
      }
      return valid;
    },
    save() {
      if (this.validate()) {
        const formData = new FormData();
        for (const i in this.result) {
          if (this.result[i] instanceof Array) {
            formData.append(i, JSON.stringify(this.result[i]));
          } else {
            formData.append(i, this.result[i]);
          }
        }

        if (this.result.id) {
          this.updateAcademy({
            id: this.result.id,
            data: formData,
          })
            .then(() => {
              this.$noty.success('Academy Updated Succesfully');
              this.$emit('onSaved');
              this.close();
            })
            .catch(this.showError);
        } else {
          this.createAcademy(formData)
            .then(() => {
              this.$noty.success('Academy Created Succesfully');
              this.$emit('onSaved');
              this.close();
            })
            .catch(this.showError);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group-item {
  border-bottom-width: 3px !important;

  &:last-child {
    border-bottom-width: 0 !important;
  }
}
</style>
