<template>
  <div>
    <h4>Academies</h4>

    <div class="card mt-4 ">
      <div class="card-header">
        <div
          class="btn btn-outline-primary float-right btn-sm"
          @click="newAcademy"
        >
          <i class="fas fa-plus mr-2" />
          New Academy
        </div>
      </div>
      <div class="card-body">
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="academies"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          @changed="getData"
          @onPressDelete="onPressDelete"
          @onPressEdit="onPressEdit"
        />
      </div>
    </div>

    <academies-form
      v-if="selAcademy"
      :academy="selAcademy"
      @close="selAcademy=null"
      @onSaved="resetTable"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import academiesForm from './academiesForm.vue';

export default {
  components: {
    academiesForm,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'name',
          sortable: true,
        },
        {
          title: 'city.name',
          mask: 'location',
          sortable: true,
        },
        {
          title: 'book_fee',
          mask: 'book fee',
          sortable: true,
          pre: '€',
        },
        {
          title: 'enrolment_fee',
          mask: 'enrolment fee',
          sortable: true,
          pre: '€',
        },
        {
          title: 'courses_qty',
          mask: 'courses',
        },
        {
          title: 'budget_expire_time',
          mask: 'budget expire days',
        },
      ],
      actions: [
        {
          buttonClass: 'btn-info btn-sm', // use any class you want
          callback: 'onPressEdit', // action you have to subscribe in the vue-table element
          tooltip: 'Edit', // tooltip on hover
          icon: 'fas fa-edit', // You can use any icon package, just install it before
        },
        {
          buttonClass: 'btn-danger btn-sm', // use any class you want
          callback: 'onPressDelete', // action you have to subscribe in the vue-table element
          tooltip: 'Delete', // tooltip on hover
          icon: 'fas fa-times', // You can use any icon package, just install it before
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
    selAcademy: null,
  }),
  computed: {
    ...mapGetters(['academies']),
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    ...mapActions([
      'getAcademies',
      'updateAcademy',
      'deleteAcademy',
    ]),
    getData(evt = {}) {
      this.getAcademies(evt).catch(this.showError);
    },
    newAcademy() {
      this.selAcademy = {
        name: null,
        logo: null,
        website: null,
        location: null,
        budget_expire_time: null,
        enrolment_fee: null,
        book_fee: null,
        courses: [
          {
            key: Math.random() * Date.now(),
            name: null,
            week_hours: null,
            timetable: 'morning',
            prices: [
              {
                key: Math.random() * Date.now(),
                week_price: 0,
                from_weeks: 1,
                course_id: null,
              },
            ],
          },
        ],
      };
    },
    onPressDelete(item) {
      this.fireConfirm().then(() => {
        this.deleteAcademy(item)
          .then(() => {
            this.$noty.success('Academy Deleted Succesfully');
            this.resetTable();
          })
          .catch(this.showError);
      });
    },
    onPressEdit(item) {
      this.selAcademy = item;
    },
    resetTable() {
      this.$refs.vtable.init();
    },
  },
};
</script>

<style>

</style>
